import React, { useEffect, useRef, useState } from 'react';
import { FloatingUserCell } from "./FloatingUserCell";
import { DayCell, ROW_HEIGHT, WallPlannerRow, WeekCell } from '../WallPlannerStructuralComponents';
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import styled from "styled-components/macro";
import DateFlicker, { DateScale } from "../../../UI/molecules/DateFlicker";
import Colours from "../../../UI/atoms/Colours";
import { Moment } from "moment";

interface Props {
  weeksInMonth: Moment[];
}

export function WallPlannerDatesRow(props: Props) {
  const {weeksInMonth} = props;
  const dateRowRef = useRef();
  const dateBarStartYPositionRef = useRef(10000);
  const [fixDateBar, setFixDateBar] = useState(false);
  const [offsetX, setOffsetX] = useState(0);

  useEffect(() => {
    if (dateRowRef.current) {
      const rect = (dateRowRef.current as any).getBoundingClientRect();
      dateBarStartYPositionRef.current = rect.y;
    }
  }, []);

  useEffect(() => {
    const workPlannerContainer = document.getElementById('workPlannerContainer');
    const onScroll = () => {
      if (dateRowRef.current) {
        const workPlannerRow = document.getElementById('workPlannerRow');
        if (workPlannerRow) {
          const rowRect = workPlannerRow.getBoundingClientRect()
          setOffsetX(rowRect.x);
          setFixDateBar(window.scrollY > dateBarStartYPositionRef.current);
        }
      }
    }
    window.addEventListener('scroll', onScroll);
    workPlannerContainer?.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
      workPlannerContainer?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <WallPlannerDatesRowWrapper ref={dateRowRef} weeksInMonth={weeksInMonth.length + 1}
                                offsetX={offsetX}
                                fixDateBar={fixDateBar}>
      <FloatingUserCell hideOnMobile={true}>
        <DateFlicker dateScale={DateScale.Month} updateGlobalDate={true}/>
      </FloatingUserCell>
      <WeekHeaderContainer>
        {weeksInMonth.map((timeFrame, k) => (
          <WeekHeaderCell key={k}>
            <StartOfWeekTitle weight={600}>{timeFrame.format('MMMM D')}</StartOfWeekTitle>
            <WeekHeaderDaysCell>
              <DateOfDayCell>
                <BodyRegular weight={600} colour={Colours.darkGrey}>{timeFrame.format('D')}</BodyRegular>
              </DateOfDayCell>
              <DateOfDayCell>
                <BodyRegular weight={600} colour={Colours.darkGrey}>{timeFrame.clone().add(1, 'day').format('D')}</BodyRegular>
              </DateOfDayCell>
              <DateOfDayCell>
                <BodyRegular weight={600} colour={Colours.darkGrey}>{timeFrame.clone().add(2, 'day').format('D')}</BodyRegular>
              </DateOfDayCell>
              <DateOfDayCell>
                <BodyRegular weight={600} colour={Colours.darkGrey}>{timeFrame.clone().add(3, 'day').format('D')}</BodyRegular>
              </DateOfDayCell>
              <DateOfDayCell>
                <BodyRegular weight={600} colour={Colours.darkGrey}>{timeFrame.clone().add(4, 'day').format('D')}</BodyRegular>
              </DateOfDayCell>
              <DateOfDayCell>
                <BodyRegular weight={600} colour={Colours.darkGrey}>{timeFrame.clone().add(5, 'day').format('D')}</BodyRegular>
              </DateOfDayCell>
              <DateOfDayCell>
                <BodyRegular weight={600} colour={Colours.darkGrey}>{timeFrame.clone().add(6, 'day').format('D')}</BodyRegular>
              </DateOfDayCell>
            </WeekHeaderDaysCell>
          </WeekHeaderCell>
        ))}
      </WeekHeaderContainer>
    </WallPlannerDatesRowWrapper>
  )
}

export const WallPlannerDatesRowWrapper = styled<any>(WallPlannerRow)<any>`
  height: ${ROW_HEIGHT}px;
  width: 100%;
  position: ${props => props.fixDateBar ? 'fixed' : 'relative'};
  top: ${props => props.fixDateBar ? '0px' : 'unset'};
  z-index: ${props => props.fixDateBar ? '1001' : 'unset'};
  left: ${props => props.fixDateBar ? `${props.offsetX}px` : `0`};
  .floatingUser__userColumn {
    min-height: 40px;
  }
  .weekFlicker .weekFlicker__control {
  }
`

export const WeekHeaderCell = styled<any>(WeekCell)`
  background: white;
  height: 100%;
  min-height: 44px;
  border-right: 1px solid ${Colours.mildGrey};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const WeekHeaderDaysCell = styled<any>(WeekCell)`
  border-right: 1px solid ${Colours.mildGrey};
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const StartOfWeekTitle = styled<any>(BodyRegular)<any>`
  padding-left: 16px;
`

export const DateOfDayCell = styled<any>(DayCell)<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 12px;
    line-height: 18px;
  }
`

export const WeekHeaderContainer = styled.div`
  display: flex;
  flex: 1;
  box-shadow: 0 9px 22px rgba(46, 74, 148, 0.08);
  z-index: 10;
`
